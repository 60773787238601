import Cereali from "./img/Cereali";
import Crostacei from "./img/Crostacei";
import Uova from "./img/Uova";
import Pesce from "./img/Pesce";
import Arachidi from "./img/Arachidi";
import Soia from "./img/Soia";
import Latte from "./img/Latte";
import FruttaGuscio from "./img/FruttaGuscio";
import Sedano from "./img/Sedano";
import Senape from "./img/Senape";
import SemiSesamo from "./img/SemiSesamo";
import AnidrideSolforosaSolfiti from "./img/AnidrideSolforosaSolfiti";
import Lupini from "./img/Lupini";
import Molluschi from "./img/Molluschi";
import { useRef } from "react";

export default function Allergens({ allergens, color = "red", backgroundColor = "pink" }) {
	return (
		<>
			{allergens.map((allergen, i) => (
				<Allergen
					key={i}
					allergen={allergen}
					color={color}
					backgroundColor={backgroundColor}
				/>
			))}
		</>
	);
}

function Allergen({ allergen, color, backgroundColor }) {
	const button = useRef();
	const tooltip = useRef();

	function showTooltip() {
		console.log(button.current.offsetHeight);
		tooltip.current.style.bottom = button.current.offsetHeight + 5 + "px";
		tooltip.current.style.display = "block";
	}

	function hideTooltip() {
		tooltip.current.style.display = "none";
	}

	function toggleTooltip() {
		if (tooltip.current.style.display == "none") showTooltip();
		else hideTooltip();
	}

	return (
		<div
			style={{ backgroundColor: backgroundColor, position: "relative" }}
			ref={button}
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
			onClick={toggleTooltip}
		>
			{allergen.id == 1 && <Cereali color={color} />}
			{allergen.id == 2 && <Crostacei color={color} />}
			{allergen.id == 3 && <Uova color={color} />}
			{allergen.id == 4 && <Pesce color={color} />}
			{allergen.id == 5 && <Arachidi color={color} />}
			{allergen.id == 6 && <Soia color={color} />}
			{allergen.id == 7 && <Latte color={color} />}
			{allergen.id == 8 && <FruttaGuscio color={color} />}
			{allergen.id == 9 && <Sedano color={color} />}
			{allergen.id == 10 && <Senape color={color} />}
			{allergen.id == 11 && <SemiSesamo color={color} />}
			{allergen.id == 12 && <AnidrideSolforosaSolfiti color={color} />}
			{allergen.id == 13 && <Lupini color={color} />}
			{allergen.id == 14 && <Molluschi color={color} />}
			<div className="tooltip" ref={tooltip}>
				{allergen.name}
			</div>
		</div>
	);
}
