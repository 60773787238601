import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { currencyFormat } from "./functions";
import IconDotsVertical from "./icons/dots";
import Allergens from "./Allergens";
import Tags from "./Tags";
import ProductLabels from "./ProductLabels";
import Label from "./Label";
import moment from "moment/moment";

export default function Product({ product, category = false }) {
	const {
		settings,
		getTranslatedString,
		cart,
		handleAddProduct,
		setSelectedProduct,
		cartEnabled,
		setCartEnabled,
	} = useContext(AppContext);
	const [discounted, setDiscounted] = useState(false);

	useEffect(() => {
		if (product.discounted) {
			if (category && category.id == -2 && product.discount.one_per_customer == 1) {
				setDiscounted(true);
			}
			if (category && category.id != -2 && product.discount.one_per_customer == 0) {
				setDiscounted(true);
			}
		}
	}, [product]);

	const hasVariants = (product) => {
		if (product.mandatory_notes == 1) return true;
		if (product.variants_categories.length > 0) return true;
		return false;
	};

	const hasMandatoryVariants = (product) => {
		if (product.mandatory_notes == 1) return true;
		for (const vc of product.variants_categories) {
			if (vc.min > 0) return true;
		}
		return false;
	};

	const hasVariants2 = (product) => {
		for (const vc of product.variants_categories) {
			if (vc.variants2.length > 0) return true;
		}
		return false;
	};

	return (
		<div className="product-container">
			<button
				className="product"
				onClick={() => setSelectedProduct({ ...product, discounted: discounted })}
			>
				<div className="info">
					<h3>{getTranslatedString(product, "name").replaceAll(",", ", ")}</h3>
					<ProductLabels product={product} discounted={discounted} />
					<Tags product={product} />
					{product.description && (
						<p
							className="description smoot"
							dangerouslySetInnerHTML={{
								__html: getTranslatedString(product, "description").replaceAll(
									",",
									", "
								),
							}}
						/>
					)}
					{settings.website.show_allergens_in_products_list == 1 && (
						<div className="allergens">
							<Allergens
								allergens={product.allergens}
								color="#000"
								backgroundColor="#ccc"
							/>
						</div>
					)}
				</div>
				{product.img && (
					<div className="img">
						<img src={product.img} />
					</div>
				)}
			</button>
			<div className="product-box-footer">
				{(cartEnabled || settings.website.show_prices_if_no_list_selected == 1) && (
					<div>
						{discounted ? (
							<div>
								<s>{currencyFormat(product?.price, cart?.shop?.currency)}</s>
								{" - " +
									currencyFormat(product.discount?.price, cart?.shop?.currency)}
							</div>
						) : (
							<div>{currencyFormat(product.price, cart?.shop?.currency)}</div>
						)}
					</div>
				)}

				{product.disabled == 0 && (
					<div className="buttons">
						{hasVariants(product) && (
							<button
								onClick={() => {
									if (!cartEnabled) setCartEnabled(true);
									else setSelectedProduct({ ...product, discounted: discounted });
								}}
								className="product-add-button"
							>
								{!hasMandatoryVariants(product) && !hasVariants2(product) ? (
									<IconDotsVertical />
								) : (
									<i className="fas fa-plus"></i>
								)}
							</button>
						)}
						{!hasMandatoryVariants(product) && !hasVariants2(product) && (
							<button
								onClick={() => handleAddProduct(product, discounted ? 1 : 0)}
								className="product-add-button"
							>
								<i className="fas fa-plus"></i>
							</button>
						)}
					</div>
				)}
			</div>
			{discounted && (product.discount.date_start || product.discount.date_end) && (
				<div className="product-discount-dates">
					{product.discount.date_start && (
						<span>
							<Label number={208} />{" "}
							{moment(product.discount.date_start, "YYYY-MM-DD").format("DD/MM/YYYY")}
						</span>
					)}
					{product.discount.date_end && (
						<span>
							{" "}
							<Label number={209} />{" "}
							{moment(product.discount.date_end, "YYYY-MM-DD").format("DD/MM/YYYY")}
						</span>
					)}
				</div>
			)}
		</div>
	);
}
