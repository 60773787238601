import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import {
	currencyFormat,
	formatOrderDeliveryType,
	formatOrderStatus,
} from "../components/functions";
import UserboardMenu from "../components/UserboardMenu";
import moment from "moment/moment";
import PopUp from "../components/PopUp";
import { useNavigate } from "react-router-dom";

function Orders() {
	const { settings, setLoading, setConfirm, user } = useContext(AppContext);
	const [orders, setOrders] = useState(false);
	const [order, setOrder] = useState(false);

	useEffect(() => {
		loadOrders(); // eslint-disable-next-line
	}, []);

	const loadOrders = async () => {
		setLoading(true);
		const response = await api.postProtected("/orders/list/", {
			orderby: "id DESC",
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setOrders(response.rows);
	};

	const editOrder = async (id, request) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.postProtected("/orders/edit/" + id + "/", request);
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadOrders();
			},
		});
	};

	return (
		<>
			<div className="page">
				<div className="userboard">
					<UserboardMenu selected="orders" />
					<div className="box">
						<h1>
							<Label number={52} />
						</h1>
						{orders &&
							orders.map((order, i) => (
								<div key={i} className="orders-list">
									<div>
										N°{order.id}
										<div className="small">
											{moment(
												order.delivery_date,
												"YYYY-MM-DD HH:mm:ss"
											).format("DD/MM/YYYY HH:mm")}
										</div>
									</div>
									<div className="small">
										<Label number={formatOrderStatus(order.status)} />
									</div>
									<div className="buttons horizontal">
										{(order.status == "unconfirmed" ||
											order.status == "confirmed") &&
											settings.orders.cancellation_minutes != "" &&
											moment(order.delivery_date, "YYYY-MM-DD HH:mm:ss")
												.subtract(
													settings.orders.cancellation_minutes,
													"minutes"
												)
												.format("X") -
												moment().format("X") >
												0 && (
												<button
													className="button button-primary"
													onClick={() =>
														editOrder(order.id, {
															status: "cancel_request",
														})
													}
												>
													<i className="fas fa-trash"></i>
												</button>
											)}
										<button
											className="button button-primary"
											onClick={() => setOrder(order)}
										>
											<i className="fa fa-search" />
										</button>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			<Sheet order={order} setOrder={setOrder} />
		</>
	);
}

export default Orders;

function Sheet({ order, setOrder }) {
	const { user, setLoading, setConfirm, loadCart } = useContext(AppContext);
	const navigate = useNavigate();

	const reorder = async (id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.postProtected("/cart/reorder/" + user.id + "/", {
					order_id: id,
				});
				setLoading(false);
				if (response.success == 0) {
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				} else {
					loadCart();
					navigate("/checkout");
				}
			},
		});
	};

	if (!order) return null;

	return (
		<PopUp>
			<div className="header">
				N°{order.id}
				<button onClick={() => setOrder(false)}>
					<i className="fas fa-times"></i>
				</button>
			</div>
			<div className="content">
				<div>
					<div>
						<Label number={9} />: {order.shop.name}
					</div>
					<div>
						<Label number={69} />: <Label number={formatOrderStatus(order.status)} />
					</div>
					<div>
						<Label number={5} />:{" "}
						<Label number={formatOrderDeliveryType(order.delivery_type)} />
					</div>
					{order.delivery_type == "delivery" && (
						<>
							<div>
								<Label number={12} />:{" "}
								{order.delivery_address.address +
									" " +
									order.delivery_address.number +
									", " +
									order.delivery_address.zipcode +
									" " +
									order.delivery_address.city +
									" (" +
									order.delivery_address.province +
									")"}
							</div>
							<div>
								<Label number={34} />:{" " + order.delivery_address.doorphone}
							</div>
						</>
					)}
				</div>
				<div>
					<div className="title">Prodotti</div>
					{order.products.map((product, i) => (
						<div key={i} className="product-line">
							<div>
								{product.name}
								<div className="small">
									{product.variants.map((variant, n) => (
										<div key={n}>{variant.qty + "x " + variant.name}</div>
									))}
									{product.products.map((subproduct, n) => (
										<div key={n}>{subproduct.qty + "x " + subproduct.name}</div>
									))}
								</div>
							</div>
							<div>{product.qty}</div>
							<div>{currencyFormat(product.qty * product.price)}</div>
						</div>
					))}
					{order.delivery_type == "delivery" && (
						<div className="product-line title">
							<div>
								<Label number={14} />
							</div>
							<div></div>
							<div>{currencyFormat(order.delivery_price)}</div>
						</div>
					)}
					<div className="product-line title">
						<div>
							<Label number={15} />
						</div>
						<div></div>
						<div>{currencyFormat(order.total)}</div>
					</div>
				</div>
				<div>
					<img
						src={"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&d=" + order.id}
					/>
				</div>
				<div>
					<button onClick={() => reorder(order.id)} className="button button-primary">
						Riordina
					</button>
				</div>
			</div>
		</PopUp>
	);
}
